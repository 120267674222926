<template>
  
	<div class="login">
		
		<div class="panel" v-loading="loading">
			
			<div class="logo">
				<h3>{{$store.getters.getAppName}}管理中心</h3>
			</div>
			
			<ul>
				<el-input
				    placeholder="请输入手机号"
				    v-model="Account">
				    <i slot="prefix" class="el-input__icon el-icon-phone-outline"></i>
				  </el-input>
			</ul>
			 
			<ul>
				<el-input
				    placeholder="请输入密码"
				    v-model="Password" show-password>
				    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
				  </el-input>
			</ul>
			
			<ul style="margin-top: 30px;">
				 <el-button type="primary" style="width: 100%;" @click="Login()">登 录</el-button>
			</ul>
			
		</div>
		
		<el-dialog
		  title="提示"
		  :visible.sync="this.DialogMsg != ''"
		  width="30%"
		  :show-close="false">
		  <span>{{DialogMsg}}</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="danger" @click="DialogMsg = ''">关 闭</el-button>
		  </span>
		</el-dialog>
		
		<div class="LoadingTips" v-if="loading">
			登录中...
		</div>
		
	</div>

</template>

<script>
	import {  Input,Button,Dialog,Message,Loading } from 'element-ui';
	import md5 from 'md5';
export default {
  name: 'login',
  props: {
  },
  data() {
    return {
		Account:'',
		Password:'',
		DialogMsg:'',
		loading:false,
  	}
  },
  components: {
	  "el-input":Input,
	  "el-button":Button,
	  "el-dialog":Dialog
  },
  created() {
  	let localMobile = localStorage.getItem('Mobile')
	this.Mobile = localMobile
  },
  methods:{
	  SuccessJump(){
		  let uri = '/do/user/user_list'
		  if(this.$store.getters.getLoginDirect != ''){
			  uri = this.$store.getters.getLoginDirect
		  }
		this.$store.dispatch('setLoginDirect','')
		this.$router.push(uri)  
	  },
	  Login(){
		  this.loading = true
	  		let that = this
	  		let data = {
	  			Service:'User',
	  			Class: 'User',
	  			Action: 'Login',
	  			Account:that.Account,
	  			Password:md5(that.Password),
	  		}
	  		this.$post(that.$store.getters.getApiHost,data)
	  		.then((response) => {
	  			this.loading = false
	  			if(response.ErrorId != 0){
					that.DialogMsg = response.Msg
	  				return
	  			}
				
				if(response.Data.Identity != 'admin'){
					that.DialogMsg = '非系统管理员，无权进入'
					return
				}
	  			
	  			that.$message({
	  			  message: response.Msg,
	  			  type: 'success'
	  			});
	  			
				localStorage.setItem("LoginData",JSON.stringify(response.Data))
	  			that.$store.dispatch('setToken',response.Data.Token)
				that.$store.dispatch('setUserName',response.Data.Account)
				that.$store.dispatch('setIdentity',response.Data.Identity)
				that.$store.dispatch('setIdentityName',response.Data.IdentityName)
	  			setTimeout(that.SuccessJump(),1500)
	  			
	  		})
	  		.catch(function (response) {
				that.loading = false
	  			that.DialogMsg = '网络请求错误'
	  		})
	  }
  }
}
</script>

<style scoped>
	.login{
		width: 100%;
		height: 100%;
		background-color: rgba(46,64,88);
	}
	.login .panel{
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 100px;
	}
	.login .panel ul{
		margin-top: 15px;
		width: 300px;
	}
	.login .panel .logo{
		text-align: center;
		margin-bottom: 40px;
	}
	.login .panel .logo h3{
		height: 20px;
		line-height: 20px;
		font-size: 16px;
		font-weight: normal;
		color: rgba(255,255,255,0.4);
	}
	.LoadingTips{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0px;
		top: 0px;
		background-color: rgba(0,0,0,0.7);
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
